* {
  background-color: #212121;
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  
}

.container{
  scroll-behavior: smooth;
}

.intro {
  font-size: 80px;
  position: absolute;
  top: 25%;
  left: 15%;
  transition: transform 0.15s ease-in-out;
  animation-fill-mode: forwards;
  background-color: transparent;
}

.intro:hover {
  transform: scale(1.2);
}

@keyframes color-animation {
  0% {
    color: white;
    transform: scale(0.75, 1.25);
  }
  50% {
    color: #45f3ff;
    transform: scale(1.25, 0.75);
  }
  100% {
    color: white;
    transform: scale(1);
  }
}

span {
  cursor: default;
  display: inline-block;
  transition: transform 0.15s ease-in-out;
  animation-fill-mode: forwards;
  background-color: transparent;
  margin-left: -5px;
  padding-left: 5px;
  /* animation: color-animation 0.5s forwards; */
}

/* span.hovered { */
.bounce,
.devTitle {
  color: #45f3ff;
  transform: scale(1.2);
  transition-delay: 0s;
}

/* span:hover { */
.bounce:hover,
.devTitle:hover {
  color: #45f3ff;
  transform: scale(1.2);
}

span:not(:hover) {
  color: white;
  transform: scale(1);
}

/* span:hover { */
.bounce:hover,
.devTitle:hover {
  animation: color-animation 0.5s forwards !important;
}

.A {
  padding-left: 2px;
  font-size: 1.5em;
  color: white;
  text-shadow: #45f3ff -7px 0 0;
}

.A:hover {
  transform: scale(1.2);
}

.devTitle {
  position: relative;
  top: -30px;
  font-size: 30px;
}

.name {
  border-bottom: 1px solid #45f3ff;
  margin-bottom: -100px;
}

.scrollDown {
  rotate: 90deg;
  position: absolute;
  top: 95%;
  background-color: transparent;
}

.spacer {
  height: 90vh;
}

@keyframes scroll-move {
  0% {
    top: 90%;
  }
  50% {
    top: 87%;
  }
  100% {
    top: 90%;
  }
}

.scrollDown {
  animation: scroll-move 2s forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.movingScroll {
  background-color: transparent;
  font-size: 20px;
}

h1 {
  font-size: 50px;
  position: relative;
  left: 5%;
  width: 75%;
  cursor: default;
}

h1::first-letter {
  padding-left: 2px;
  font-size: 1.5em;
  color: white;
  text-shadow: #45f3ff -4px 0 0;
}

.aboutMe p {
  font-size: 20px;
  margin: 0 auto;
  width: 65%;
  /* border: 1px solid #45F3FF; */
  box-shadow: 0px 0px 20px #45f3ff;
  padding: 10px;
  height: 100%;
  margin-bottom: 10px;
  font-family: monospace;
  background-color: transparent;
  z-index: 2;
}

.toContact {
  display: inline-block;
  width: 100%;
  background-color: transparent;
  text-align: center;
  font-family: monospace;
  font-size: 20px;
  color: #45f3ff;
  text-shadow: gray 2px 1px 1px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  
}


.toContact:hover {
  color: black;
  /* text-shadow: #45f3ff 2px 2px 2px; */
  transform: scale(1.1);
  text-shadow: 
  -1px -1px 0 #45f3ff, /* top left shadow */
  1px -1px 0 #45f3ff,  /* top right shadow */
  -1px 1px 0 #45f3ff,  /* bottom left shadow */
  1px 1px 0 #45f3ff;   /* bottom right shadow */
  cursor: pointer;
}

.scrollDown2 {
  rotate: 90deg;
  position: relative;
  left: -22%;
  top: 0%;
  margin-bottom: 50vh;
  text-align: right;
  width: 100vh;
  background-color: blue;
}

.cards {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  gap: 16px;
  max-width: 1400px;
  margin: 0 auto;
  padding: 20px 10px;
}

.card {
  width: calc(100vh / 3);
  height: calc((100vh / 3) * 1.25);
  background-image: linear-gradient(163deg, #00ff75 0%, #3700ff 100%);
  border-radius: 20px;
  transition: all 0.3s;
}

.card2 {
  position: relative;
  width: calc(100vh / 3);
  height: calc((100vh / 3) * 1.25);
  background-color: #333;
  border-radius: 10px;
  transition: all 0.2s;
  border: 1px solid white;
}

.card2:hover {
  transform: scale(0.98);
  border-radius: 20px;
  border: none;
}

.card:hover {
  box-shadow: 0px 0px 30px 1px rgba(0, 255, 117, 0.3);
  cursor: pointer;
}

.card2 .cardTitle {
  position: absolute;
  font-size: 24px;
  width: 100%;
  text-align: center;
  margin-top: 10px;
  height: 24px;
  background-color: transparent;
  transform: scale(1);
  transition: transform 0.3s ease;
}

.card2 img {
  width: 90%;
  background-color: transparent;
  position: absolute;
  top: 10%;
  left: 5%;
  transform: scale(1);
  transition: transform 0.3s ease;
  margin-top: 40px;
  object-fit: contain;
}

.card2 .cardTitle:hover,
.card2 img:hover {
  cursor: pointer;
  transform: scale(1.2);
}

.skills{
  padding-bottom: 20px;
}
.skillsList {
  width: 80%;
  margin: auto;
  display: flex;
  flex-direction: column;
}

.skillTitle {
  display: flex;
  justify-content: space-between;
  font-size: 30px;
}

.skill {
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding-bottom: 10px;
}

.skillSpan {
  display: flex;
  border: 1px solid white;
  border-radius: 5px;
  padding-right: 2px;
}

.skillBar {
  width: 100%;
  height: 30px;
  background-color: #2b3945;
  margin-top: 5px;
}

.barPercent {
  height: 30px;
  background-color: #a1eafb;
}
.eighty {
  width: 80%;
}

.seventyFive {
  width: 75%;
}

.eightyFive {
  width: 85%;
}

.fifty {
  width: 50%;
}

.forty {
  width: 40%;
}


.contact{
  padding-bottom: 50px;
}

.formBox{
  width: 75%;
  height: 50%;
  position: relative;
  justify-content: center;
  left: 50%;
  transform: translate(-50%);
  border: 1px solid white;
  padding-top: 50px;
  padding-bottom: 50px;
}

.nameEmail{
  display: flex;
  justify-content: space-evenly;
}

.inputbox {
  position: relative;
  /* left: 20%; */
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* , .inputbox textarea */
.inputbox input {
  position: relative;
  width: 100%;
  padding: 20px 10px 10px;
  background: transparent;
  outline: none;
  box-shadow: none;
  border: none;
  color: #23242a;
  font-size: 1em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  z-index: 10;
  
}

/* .inputbox textarea {
  color: white;
} */

.inputbox span {
  position: absolute;
  left: 5px;
  padding: 20px 10px 10px;
  font-size: 1em;
  color: #8f8f8f;
  letter-spacing: 00.05em;
  transition: 0.5s;
  pointer-events: none;
}

.inputbox input:valid ~ span,
.inputbox input:focus ~ span
/* .inputbox textarea:valid ~ span,
.inputbox textarea:focus ~ span{ */{
  color: #45f3ff;
  transform: translateX(-10px) translateY(-34px);
  font-size: 0, 75em;
}

.inputbox i {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: #45f3ff;
  border-radius: 4px;
  transition: 0.5s;
  pointer-events: none;
  z-index: 9;
}

.inputbox input:valid ~ i,
.inputbox input:focus ~ i{
  height: 44px;
}


.subject{
  position: relative;
  left: 5%;
  padding-top: 20px;
  width: 90%;
}


.inputbox textarea:valid ~ i,
.inputbox textarea:focus ~ i{
  height: 300px;
}


.inputbox textarea:valid ~ span,
.inputbox textarea:focus ~ span{
  color: #45f3ff;
  transform: translateX(-10px) translateY(-34px);
  font-size: 0, 75em;
  height: 300px;
}

.message{
  display: flex;
  flex-direction: column;
  position: relative;
  left: 5%;
  padding-top: 20px;
  width: 90%;
  height: 300px;
}

.messageP{
  text-align: left;
  font-size: 24px;
  font-weight: bold;
}

textarea{
  width: 100%;
  max-width: 100%;
  height: 100%;
  font-size: 20px;
}

.textarea:focus{
  background-color: #45f3ff;
}






/* sendMessageBtn css by mrhyddenn */
.sendMessageBtn {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 2%;
  padding: 10px 20px;
  border-radius: 7px;
  border: 1px solid rgb(31, 72, 207);
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  background: transparent;
  color: #fff;
  overflow: hidden;
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.sendMessageBtn:hover {
  background: rgb(26, 57, 158);
  box-shadow: 0 0 30px 5px rgb(31, 72, 207, 0.8);
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  cursor: pointer;
}

.sendMessageBtn:hover::before {
  -webkit-animation: sh02 0.5s 0s linear;
  -moz-animation: sh02 0.5s 0s linear;
  animation: sh02 0.5s 0s linear;
}

.sendMessageBtn::before {
  content: '';
  display: block;
  width: 0px;
  height: 86%;
  position: absolute;
  top: 7%;
  left: 0%;
  opacity: 0;
  background: #fff;
  box-shadow: 0 0 50px 30px #fff;
  -webkit-transform: skewX(-20deg);
  -moz-transform: skewX(-20deg);
  -ms-transform: skewX(-20deg);
  -o-transform: skewX(-20deg);
  transform: skewX(-20deg);
}

@keyframes sh02 {
  from {
    opacity: 0;
    left: 0%;
  }

  50% {
    opacity: 1;
  }

  to {
    opacity: 0;
    left: 100%;
  }
}

.sendMessageBtn:active {
  box-shadow: 0 0 0 0 transparent;
  -webkit-transition: box-shadow 0.2s ease-in;
  -moz-transition: box-shadow 0.2s ease-in;
  transition: box-shadow 0.2s ease-in;
}


.scroll-up {
  position: fixed;
  width: 100px;
  bottom: 10px;
  right: 0;
  opacity: 0%;
  transition: opacity 0.5s ease-in-out;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.scroll-up.scroll-up-active {
  background-color: transparent;
  opacity: 1;
  animation: scroll-animation 5s ease-in-out;
}

.scroll-up.scroll-up-active:hover{
  cursor: pointer;
}



@keyframes scroll-animation {
  0%{
    bottom: 10px;
  }
  25%{
    bottom: 15px;
  }
  50%{
    bottom: 10px;
  }
  75%{
    bottom: 10px;
  }
  100%{
    bottom: 10px;
  }
}

.arrow-up {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}


.arrow-down {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
}

.introSpacer{
  display: none;
}

.titleBreak{
  display: none;
}




@media (max-width: 1200px){
  .cards {
    width: 75%;
    display: grid;
    grid-column-gap: 0px;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: center;
  }
  
  
}



@media (max-width: 1000px) { 
  .intro{
    font-size: 60px;
  }
  /* .introSpacer { 
    height: 0px;
    display: block;
  } */

  .titleBreak{
    display: block;
  }

  .spacer{
    height: 95vh;
  }
}

@media (max-width: 600px) {
  .intro{
    width: 75vw;
  }
  .bounce{
    font-size: 50px;
  }
  .introSpacer{
    display: block;
    margin-top: -75px;
  }

  .A{
    padding-left: 5px;
    text-shadow: #45f3ff -3px 0 0;
  }

  .devTitle{
    font-size: 22px;
    top: -50px;
  }
}

@media (max-width: 775px) {
  .cards{
    display: flex;
    flex-direction: column;
  }
}