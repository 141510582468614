/* Majority of this modal starter CSS code from Thi Tran
 https://medium.com/tinyso/how-to-create-a-modal-component-in-react-from-basic-to-advanced-a3357a2a716a
*/
.modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  pointer-events: none;
}

.modal.enter-done {
  opacity: 1;
  pointer-events: visible;
}

.modal.exit {
  opacity: 0;
}

.modal-content {
  position: relative;
  width: 75vw;
  height: 75vh;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  transform: translateY(-200px);
}

.modal.enter-done .modal-content {
  transform: translateY(0);
}

.modal.exit .modal-content {
  transform: translateY(-200px);
}

.modal-header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  padding: 10px;
}

.modal-header,
.modal-exit {
  padding: 10px;
  height: 10%;
}

.modal-title {
  margin: 0;
}

.modal-body {
  height: 86%;
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  font-size: 24px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

.modal-body .widePic{
  justify-content: center;
  margin: 0 auto;
  width: 60%;
}

.thin{
  display: flex;
  flex-direction: row;
}

.modal-body img .wide{
  object-fit: cover;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  /* margin-top: 10px; */
}

.modal-body img .thin{
  object-fit: cover;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 50%;
  height: 50%;
}

.modal-links {
  display: flex;
  justify-content: space-evenly;
  height: 10%;
  padding-bottom: 10px;
  align-items: center;
}

.link {
  display: flex;
  justify-content: center;
  background-color: darkblue;
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px;
  align-items: center;
}

.modal-exit {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
}

.button {
  width: 25px;
  height: 25px;
  background-color: #eee;
  color: darkgray;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  border: 2px solid black;
  border-radius: 5px;
  color: black;
}

.button:hover{
  cursor: pointer;
}

.modal-border {
  background-color: #fff;
  border: 10px solid white;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
}

.modal-border * {
  box-sizing: border-box;
}

.modal-content {
  padding: 0;
  margin-bottom: 30px;
}

.space {
  height: 10px;
  width: 10px;
  background-color: transparent;
}

@media (max-width: 1000px){
  .modal-body{
    font-size: 16px;
  }
  .thin{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .thin img{
    height: 50%;
    width: 75%;
  }
  .modal-body .widePic{
    width: 100%;
    height: 40%;
    align-items: center;
  }

  .modal-title{
    font-size: 20px;
  }
}