nav{
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  height: 10vh;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

nav::after{
  content: "";
  position: absolute;
  top: 10vh;
  left: 0;
  width: 100%;
  height: 10px;
  background-image: linear-gradient(rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.1) 100%);
}

nav a{
  font-size: 2rem;
  background-color: transparent;
}

.title{
  width: 25vw;
  font-size: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid white;
  height: 100%;
  text-align: center;
  font-weight: bold;
}

ul{
  font-weight: bold;
  list-style: none;
  font-size: 24px;
  width: 75vw;
  display: flex;
  justify-content: space-around;

}

li{
  transition: transform 0.15s ease-in-out;
}

li:hover{
  transform: scale(1.2);
  cursor: pointer;
}

a {
  text-decoration: none;
  font-size: 20px;
}

a:hover {
  border-radius: 5px;
}

.title:hover{
  cursor: default;
}

@media (max-width: 1000px) {
  nav a{
    font-size: 20px;
  }

  .title{
    font-size: 22px;
  }

  nav{
    height: 8vh;
  }

  nav::after{
    top: 8vh;
  }
}

@media (max-width: 600px){
  ul{
    display: flex;
    justify-content: space-around;
  }
  nav a{
    font-size: 14px;
    
  }

  .title{
    font-size: 16px;
  }
}